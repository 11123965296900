/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.min.css";

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

html,
body {
  height: 100%;
  margin: 0;

  font-family: "Roboto", sans-serif;
  // font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

// For a list of Bootstrap variables you can override, look at node_modules\bootstrap\scss\_variables.scss

// These are the defaults, but you can override any values
// $font-family-sans-serif: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
//   sans-serif !default;
// $font-family-serif: Georgia, "Times New Roman", Times, serif !default;
// $font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// $font-family-base: $font-family-sans-serif !default;
$font-family-sans-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

.leaflet-container {
  font: 18px/1.5 "Roboto", sans-serif;
  // font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 18px;
  line-height: 1.5;
  font-family: "Roboto", sans-serif;
}
/*

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
</style>

*/
